$primary-color: #10069f;
$secondary-color: #ffdea8;
$dark: rgb(33, 33, 37);

$color-black: #191c1d;
$color-white: #fff;
$color-gray: #ddd;
$color-dark-gray: #4f5759;

.productContent {
    font-family: 'Inter', sans-serif;
    background: #fff;
    width: 100%;
    overflow: hidden;

    &__item {
        margin: 0em auto;
        max-width: 1440px;
        padding: 1.5em 0;
        background: #fff;
        color: lighten($dark, 20%);
        line-height: 1.5;

        @media (min-width: 768px) {
            padding: 2.75em 16px;
        }

        .item {
            &__col {
                flex: 1 0 0;
                padding: 0 1em;
            }

            &__row {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin: 0 -1em;
            }

            &__img {
                text-align: center;

                img {
                    width: auto;
                    max-width: 100%;
                    height: auto;

                    @media only screen and (max-width: 768px) {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &__txt {
                padding: 0 0;

                p:last-child {
                    margin-block-end: 0;
                }
            }

            &__video {
                text-align: center;
                background-color: rgba($secondary-color, .5);
                padding: 2em;
            }

            &__summary {
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        position: relative;
                        padding: .3em 1em .3em 2.75em;

                        &::before {
                            content: '';
                            transform: rotate(-45deg);
                            border: 1px solid $primary-color;
                            border-right: 0;
                            border-top: 0;
                            width: 12px;
                            height: 10px;
                            /* stylelint-disable declaration-no-important */
                            position: absolute !important;
                            inset-inline-start: 0;
                            inset-block-start: .5em;
                        }

                        &:last-child {
                            margin-block-end: 0;
                        }
                    }
                }
            }
        }
    }

    &__item &__item {
        &:first-child {
            padding-block-start: 0;
        }

        &:last-child {
            padding-block-end: 0;
        }
    }

    .contentImgTxt,
    .contentTxtImg {
        .item {
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 768px) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            &__img {
                flex: 3;
                position: relative;
                z-index: 1;
                margin: 0 0 15px;

                @media only screen and (min-width: 768px) {
                    &::before {
                        content: '';
                        height: 90%;
                        width: 50vw;
                        position: absolute;
                        z-index: -1;
                        background-color: rgba($secondary-color, .5);
                    }
                }
            }

            &__txt {
                flex: 5;
            }
        }
    }

    .contentImgTxt {
        .item {
            &__img {
                order: 1;

                @media only screen and (min-width: 768px) {
                    padding-inline-end: 2em;
                }

                &::before {
                    inset-inline-end: 20%;
                    inset-block-start: -10%;
                }
            }

            &__txt {
                order: 2;

                @media only screen and (min-width: 768px) {
                    padding-inline-start: 2em;
                }
            }
        }
    }

    .contentTxtImg {
        .item {
            &__img {
                order: 1;

                @media only screen and (max-width: 768px) {
                    margin: 15px 0;
                }

                @media only screen and (min-width: 768px) {
                    padding-inline-start: 2em;
                    order: 2;
                }

                &::before {
                    inset-inline-start: 20%;
                    inset-block-start: 20%;
                }
            }

            &__txt {
                order: 2;

                @media only screen and (min-width: 768px) {
                    padding-inline-end: 2em;
                    order: 1;
                }
            }
        }
    }

    .contentTxtSummary {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__summary {
                flex: 4;
                margin-inline-start: 2em;
                padding: 2em;
                border: 3px solid $primary-color;
            }

            &__txt {
                flex: 5;
                padding-inline-end: 2em;
            }
        }
    }

    .contentSummaryTxt {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__summary {
                flex: 4;
                margin-inline-end: 2em;
                padding: 2em;
                border: 3px solid $primary-color;
            }

            &__txt {
                flex: 5;
                padding-inline-start: 2em;
            }
        }
    }

    .contentSummaryFull {
        .item {
            &__summary {
                padding: 2em;
                border: 3px solid $primary-color;
            }
        }
    }

    .contentImgFull {
        .item {
            &__img {
                position: relative;
                z-index: 1;

                &::before {
                    content: '';
                    inset-inline-start: 50%;
                    margin-inline-start: -50vw;
                    height: 70%;
                    inset-block-start: 40%;
                    width: 100vw;
                    position: absolute;
                    z-index: -1;
                }
            }
        }
    }

    .contentColumnTwoImgTxt,
    .contentColumnThreeImgTxt,
    .contentColumnFourImgTxt {
        img {
            margin-block-end: 1.5em;

            &:last-child {
                margin-block-end: 1.5em;
            }
        }
    }

    h2,
    h3,
    h4,
    h5 {
        color: $dark;
        margin-block-start: 0em;
        font-weight: 700;
    }

    p {
        margin: 0 0 1.5em;

        &:last-child {
            margin-block-end: 0;
        }
    }

    img {
        margin-block-end: 1.5em;

        &:last-child {
            margin-block-end: 0;
        }
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 0;
        counter-reset: cnt;

        li {
            position: relative;
            padding: .65em 1em .65em 3.25em;
            counter-increment: cnt;

            &::before {
                content: counter(cnt);
                border: 1px solid $primary-color;
                font-size: .825em;
                text-align: center;
                line-height: 30px;
                color: $primary-color;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                position: absolute;
                inset-inline-start: 0;
                inset-block-start: .4em;
            }
        }
    }
}
