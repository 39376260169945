@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@import '../../style/pimcore/product_page_description';

.ProductInformation {
    &-Wrapper {
        @include desktop {
            padding-block-start: 0;
        }
    }

    &-ShortDescription {
        padding: 10px 30px 0 30px;
        margin: 0 45px;
        font-size: $fs-s;
        line-height: 24px;

        @include mobile {
            padding: 0;
            margin: 0;
        }

        li {
            list-style-type: disc;
            list-style-position: outside;

            &::before {
                display: none;
            }
        }

        ul {
            padding: 0 16px;
        }

        ul > li > ul {
            padding-inline: 16px;

            @include desktop {
                padding-inline: 32px;
            }
        }
    }

    &-ShortDescription,
    &-Description,
    .CmsBlock-Wrapper {
        p,
        span,
        li,
        a,
        i,
        strong {
            /* stylelint-disable declaration-no-important */
            font-size: $fs-xs !important;
            font-family: $font-muli !important;
        }

        li {
            &::before {
                position: static !important;
            }
        }
    }
}
